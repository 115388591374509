<template>
    <v-card class="overflow-y-hidden mt-3" elevation="0">
        <v-card-text class="categories-content">
            <v-chip v-for="(seat, index) in seats" :key="index" class="ml-1 mb-1"><v-chip class="ml-1" x-small :color="seat.color"></v-chip> {{ seat.name }}</v-chip>
            <template v-if="!!$slots.default" class="rounded-lg px my-0">
                <slot></slot>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
export default{
    name: "SelectedSeatsData",
    props: {
        selectedSeats: {
            type: Array,
            default: () => []
        },
        seatCategories: {
            type: Array,
            required: true
        },
    },
    computed:{
        seats(){
            return this.selectedSeats.map(seat => ({
                ...seat,
                color: this.getCategoryColor(seat.category), 
                name: seat.table_name
                    ? `${this.$t('generic.lang_table')} ${seat.table_name} - ${this.$t('eventbee.lang_seat')} ${seat.seat_number}`
                    : `${this.$t('eventbee.lang_row')} ${seat.row_number} - ${this.$t('eventbee.lang_seat')} ${seat.seat_number}`
            }))
        },
        categoriesMap() {
            return new Map(this.seatCategories.map(category => [category.id, category]));
        },
    },
    methods: {
        getCategoryColor(categoryId) {
            const category = this.categoriesMap.get(Number(categoryId));
            return category ? category.color : 'grey'; // Default color if category not found
        },
    }
}

</script>