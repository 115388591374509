<template>
  <v-container fluid>
    <v-row no-gutters class="text-left fade-transition">
      <v-col cols="12" class="pb-2">
        <EventTicketHeaderComponent :ticket="ticket" />
      </v-col>
      <v-col cols="12" v-if="!isEntryTicket">
        <div v-if="hasSeatCategories" class="d-flex justify-center">
          <v-btn-toggle class="mt-2" v-model="seatSelectionMode" mandatory borderless color="primary"
            background-color="white" style="width: 90% !important;">
            <v-btn v-if="seatSelectionModeOption === 0 || seatSelectionModeOption === 1" value="best-seat" width="50%"
              class="ma-0 mx-auto">
              <v-col class="pa-0">
                <v-icon size="20">mdi-sofa-single</v-icon>
                <div class="caption font-weight-bold">{{ $t('eventbee.lang_bestSeat') }}</div>
              </v-col>
            </v-btn>
            <v-btn v-if="seatSelectionModeOption === 0 || seatSelectionModeOption === 2" value="choose-seat" width="50%"
              class="ma-0 mx-auto" @click="showSeatingMap = true" :disabled="loadingReservedSeats"
              :loading="loadingReservedSeats">
              <v-col class="pa-0">
                <v-icon size="20">mdi-map-legend</v-icon>
                <div class="caption font-weight-bold">{{ $t('eventbee.lang_chooseSeat') }}</div>
              </v-col>
            </v-btn>
          </v-btn-toggle>
        </div>

        <SeatCategoriesPicker v-if="hasSeatCategories && seatSelectionMode === 'best-seat'" :personalizeTicket="personalizeTicket"
          :seatCategories="ticket.used_seat_categories" @setSelectedCategories="selected_categories = $event"
          :selectedCategories="selected_categories">
          <v-text-field v-if="askValidFrom" class="my-2" type="date" dense
            :min="$moment.utc().add(1, 'day').format('YYYY-MM-DD')" outlined v-model="validFrom"
            :label="$t('generic.lang_validFrom')" />
        </SeatCategoriesPicker>
        <SelectedSeatsData v-else-if="hasSeatCategories && seatSelectionMode === 'choose-seat'"
          :selected-seats="selected_seats" :seatCategories="ticket.used_seat_categories">
          <v-text-field v-if="askValidFrom" class="my-2" type="date" dense
            :min="$moment.utc().add(1,'day').format('YYYY-MM-DD')" outlined v-model="validFrom"
            :label="$t('generic.lang_validFrom')" />
        </SelectedSeatsData>
        <TicketsPickerComponent v-else :prices="prices" v-model="selected_prices" :max-places="available_places" :personalizeTicket="personalizeTicket">
          <v-text-field v-if="askValidFrom" class="my-2" type="date" dense
            :min="$moment.utc().add(1,'day').format('YYYY-MM-DD')" outlined v-model="validFrom"
            :rules="[(v)=>!!v || $t('generic.lang_required')]" :label="$t('generic.lang_validFrom')" />
        </TicketsPickerComponent>
        <v-row v-if="hasCustomAttributes || personalizeTicket" no-gutters justify="center">
          <v-col cols="12" md="7">
            <v-btn color="primary" depressed block @click="priceToggleNext(true)" :disabled="!canToggleAttributes"
              class="mt-2 mx-auto">{{ $t('generic.lang_next') }}
            </v-btn>
          </v-col>
        </v-row>
        <!-- event attribute -->
        <CustomAttributesForm v-if="hasCustomAttributes" v-model="selectedAttributes" :attributes="attribute || []"
          :expanded="isAttributeExpanded" @toggle="toggleAttribute" @toggleNext="toggleTicketsNames" :showNext="personalizeTicket" :canToggleNext="canContinue" />
          
        <PersonalizeTicketsForm v-if="personalizeTicket" :hasCategories="hasSeatCategories" :seatSelectionMode="seatSelectionMode"
          v-bind:selected-prices="selected_prices" v-bind:selected-seats="selected_seats" v-bind:selected-categories="selected_categories"
          :expanded="isTicketsNamesExpanded" @toggle="toggleTicketsNames" />
      </v-col>
      <v-col v-else cols="12">
        <EntryTicketComponent ref="entryTicket" :ticket="ticket" @bookTicket="emitEntryTicketData"
          :selected-seats="selected_seats" @showSeatingMap="showSeatingMap = true" />
      </v-col>
      <SeatingMapSelect v-if="showSeatingMap && hasSeatCategories" :selected-seats="selected_seats"
        :ticketId="ticket.uuid" :datetime="selectedDateTime" :reservedSeats="reserved_seats" :personalizeTicket="personalizeTicket"
        @closeDialog="showSeatingMap = false" :seatCategories="seatCategories" :seatingData="ticket.seating_data?.data"
        :backgroundImageUrl="ticket.seating_data?.backgroundImage" @setSelectedSeats="setSelectedSeats" />
    </v-row>
  </v-container>
</template>

<script>
import EventTicketHeaderComponent from "./EventTicketHeaderComponent";
import TicketsPickerComponent from "./TicketsPickerComponent";
import EntryTicketComponent from "./EntryTicketComponent";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import CustomAttributesForm from "./CustomAttributesForm";
import SeatingMapSelect from "./SeatingMapSelect.vue";
import { ENDPOINTS } from "../../../../config";
import { Events } from "../../../../plugins/events";
import SelectedSeatsData from "./SelectedSeatsData.vue";
import SeatCategoriesPicker from "./SeatCategoriesPicker.vue";
import PersonalizeTicketsForm from "./PersonalizeTicketsForm.vue";

export default {
  name: "EventTicketComponent",
  components: {
    CustomAttributesForm,
    SeatCategoriesPicker,
    EntryTicketComponent, 
    TicketsPickerComponent, 
    FontAwesomeIcon,
    EventTicketHeaderComponent,
    PersonalizeTicketsForm,
    SelectedSeatsData,
    SeatingMapSelect
  },
  props:{
    ticket: {
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return{
      selected_prices: [],
      seatCategories: this.ticket.used_seat_categories,
      isAttributeExpanded: false,
      isTicketsNamesExpanded: false,
      showSeatingMap: false,
      loadingReservedSeats: false,
      selectedAttributes:[],
      selected_seats: [],
      selected_categories: [],
      reserved_seats: [],
      validFrom: null,
      seatSelectionMode: this.seatSelectionModeOption === 0 || this.seatSelectionModeOption === 1? 'best-seat' : 'choose-seat',
      selectedTime: null
    }
  },
  inject:['event', 'date'],
  watch:{
    selected_prices:{
      handler(v){
        this.emitData();
      },
      deep:true,
    },
    selectedAttributes:{
      handler(v){
        this.emitData();
      },
      deep:true,
    },
    selected_categories:{
      handler(v){
        this.emitData();
      },
      deep:true,
    },
    validFrom(v){
      this.emitData();
    },
    seatSelectionMode(v){
      this.emitData();
    },
  },
  computed:{
    personalizeTicket(){
      return this.event()?.personalizeTicket || false;
    },
    selectedDateTime(){
      return `${this.ticket.date} ${this.selectedTime || this.ticket.start_time}`;
    },
    seatSelectionModeOption(){
      return Number(this.$store.getters['settings/getSettingValue']('eb_seatSelectionMode')) || 0;
    },
    hasSeatCategories(){
      return this.ticket?.used_seat_categories?.length > 0 || false
    },
    validTime(){
      return this.ticket?.validTime;
    },
    askValidFrom(){
      return this.ticket?.askValidFrom === 1;
    },
    attribute() {
      return this.ticket?.attributes;
    },
    available_places() {
        return this.ticket.available_places;
    },
    isTicketOpenAllDay() {
      return this.ticket.is_open_day ?? false;
    },
    isTicketHasArrivalTimes() {
      return this.ticket.has_arrival_time && this.isTicketOpenAllDay;
    },
    hasCustomAttributes() {
      return this.attribute && this.attribute.length > 0;
    },
    prices() {
      return this.ticket?.prices ?? [];
    },
    isEntryTicket() {
      return this.event()?.type === 0;
    },
    canContinue() {
      if (!this.isAnyAttributeSelected) return false;
      
      if (!this.isBookedRequiredPrices && !this.hasSeatCategories ) return false;

      if (this.hasSeatCategories){
        if(this.seatSelectionMode === 'best-seat' && this.selected_categories.length < 1) return false;
        
        if(this.seatSelectionMode === 'choose-seat' && this.selected_seats.length < 1) return false;
      }

      if (this.askValidFrom) return this.validFrom;

      return true;
    },
    canToggleAttributes(){
      if (!this.isBookedRequiredPrices && !this.hasSeatCategories) return false;
      if (this.hasSeatCategories) {
        if (this.seatSelectionMode === 'best-seat' && this.selected_categories.length < 1){
          return false;
        }
        if(this.seatSelectionMode === 'choose-seat' && this.selected_seats.length < 1){
          return false;
        }
      }
      if (this.askValidFrom) return this.validFrom;
      return true;
    },
    isAnyAttributeSelected() {
      if (this.hasCustomAttributes)
        if (this.selectedAttributes.length > 0)
          return (
              this.selectedAttributes.filter(attr => attr.required && !attr.value)
                  .length === 0
          );
        else return false;
      return true;
    },
    isBookedRequiredPrices() {
      const pricesWithRequiresBookingIds =
          this.prices
              ?.filter(price => price.requiresBooking)
              ?.map(price => price.id) ?? [];

      return pricesWithRequiresBookingIds.every(priceId => {
        const selectedPrice = this.selected_prices.find(
            price => price.id === priceId
        );
        return selectedPrice?.selected_quantity > 0;
      }) && this.selected_prices.length > 0;
    },
  },
  methods:{
    getReservedSeats(time = null) {

      if(!this.hasSeatCategories)
        return;

      this.loadingReservedSeats = true;
      this.axios.post(ENDPOINTS.POS.EVENTBEE.RESERVED_SEATS, {
        datetime: `${this.ticket.date} ${time || this.ticket.start_time}`,
        ticket_uuid: this.ticket.uuid,
      }).then(res => {
        if (res.data.status) {
          this.reserved_seats = res.data.data.reserved_seats;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
        });
      }).finally(() => {
        this.loadingReservedSeats = false;
      })

    },
    setSelectedSeats(seats){
      this.selected_seats = seats;
      if(this.isEntryTicket){
        this.$refs.entryTicket.emitData();
      }else {
        this.emitData()
      }
    },
    emitData(){
      this.$root.$emit('bookTicket', {
        selectedTimeSlot: null,
        selectedArrivalTime: null,
        selectedPrices: this.selected_prices,
        ticket_uuid:this.ticket.uuid,
        attribute: this.selectedAttributes,
        canBook: this.canContinue,
        validFrom: this.validFrom,
        askValidFrom: this.askValidFrom,
        validTime: this.validTime,
        start_time: this.ticket.start_time,
        end_time: this.ticket.end_time,
        selected_seats: this.hasSeatCategories && this.seatSelectionMode === 'choose-seat' ? this.selected_seats : undefined,
        selected_categories: this.hasSeatCategories && this.seatSelectionMode === 'best-seat' ? this.selected_categories : undefined,
      })
    },
    emitEntryTicketData(data){
      const canBook =  data.seatSelectionMode === 'choose-seat' && this.hasSeatCategories ? this.selected_seats.length >= 1 : true;

      if(data.updateTime){
        if (data.selectedTimeSlot) {
          this.selectedTime = data.selectedTimeSlot.from;
        } else if (data.selectedArrivalTime) {
          this.selectedTime = data.selectedArrivalTime.arrival_time;
        }else {
          this.selectedTime = null;
        }
      }

      this.$root.$emit('bookTicket', {
       ...data,
       canBook: data.canBook && canBook,
       selected_seats: data.seatSelectionMode === 'choose-seat' ? this.selected_seats : undefined,
      })
    },
    priceToggleNext(toggle=false) {
      // should validate that the user has selected a price
      if (!this.isBookedRequiredPrices && !this.hasSeatCategories) return;

      if (this.hasSeatCategories){
        if(this.seatSelectionMode === 'best-seat' && this.selected_categories.length < 1) return;
        
        if(this.seatSelectionMode === 'choose-seat' && this.selected_seats.length < 1) return;
      }

      if(this.hasCustomAttributes){
        this.isAttributeExpanded = toggle || !this.isAttributeExpanded;  
        this.isTicketsNamesExpanded = false;
      }else if(this.personalizeTicket){
        this.isTicketsNamesExpanded = toggle || !this.isTicketsNamesExpanded;
        this.isAttributeExpanded = false;
      }
    },
    toggleAttribute(toggle=false) {
      // should validate that the user has selected a price
      if (!this.isBookedRequiredPrices && !this.hasSeatCategories) return;

      if (this.hasSeatCategories){
        if(this.seatSelectionMode === 'best-seat' && this.selected_categories.length < 1) return;
        
        if(this.seatSelectionMode === 'choose-seat' && this.selected_seats.length < 1) return;
      }

      this.isTicketsNamesExpanded = false;
      this.isAttributeExpanded = toggle || !this.isAttributeExpanded;
    },
    toggleTicketsNames(toggle=false) {
      // should validate that the user has selected a price

      if(!this.canContinue) return;

      this.isAttributeExpanded = false;
      this.isTicketsNamesExpanded = toggle || !this.isTicketsNamesExpanded;
    },
  },
  mounted() {
    if(this.seatSelectionModeOption === 0 || this.seatSelectionModeOption === 1){
      this.seatSelectionMode = 'best-seat'
    }else {
      this.seatSelectionMode = 'choose-seat'
    }
  }
}
</script>

<style scoped>

</style>