<template>
  <v-dialog v-model="showDialog" fullscreen persistent class="overflow-hidden">
    <v-card height="100%">
      <v-card-title class="d-flex justify-center px-0 py-1 white"
        style="width: 100%;position: sticky;top: 0;z-index: 1;border-bottom: 2px lightgray solid !important;">
        <v-btn color="error" text @click="closeDialog" :loading="loading">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer />
        <div class="pa-0 px-0 ma-0">
          <v-btn color="grey darken-2" fab icon small outlined
            :disabled="$moment(date).isSameOrBefore($moment(getParsedToday)) || !!selectedEvent" @click="prevDay">
            <v-icon large> mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu ref="menu" v-model="menu" :return-value.sync="date" :close-on-content-click="false"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="!!selectedEvent" outlined color="primary" v-on="on" v-bind="attrs">
                <strong class="font-weight-bolder px-4 py-1">
                  {{ $moment(date).format('DD-MM-YYYY') }}
                </strong>
              </v-btn>
            </template>
            <v-date-picker v-model="date" :active-picker.sync="activePicker" :first-day-of-week="1"
              :min="$moment(getParsedToday).format('YYYY-MM-DD')">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $t("generic.lang_cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                {{ $t("generic.lang_apply") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn class="mr-0" color="grey darken-2" outlined :disabled="!!selectedEvent" @click="setToday">
            {{ $t('generic.lang_today') }}
          </v-btn>
          <v-btn color="grey darken-2" fab icon small outlined :disabled="!!selectedEvent" @click="nextDay">
            <v-icon large> mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <v-btn color="warning" :disabled="!selectedEvent" @click="back"
          :loading="loading"><v-icon>mdi-chevron-left</v-icon> {{ $t('generic.lang_back') }}
        </v-btn>
        <v-btn color="primary" :disabled="!canBook" @click="book" :loading="loading">{{ $t('accounting.lang_book') }}
        </v-btn>
      </v-card-title>
      <EventsComponent v-if="showDialog && !selectedEvent" v-model="selectedEvent" ref="events" />
      <EventDetailsComponent v-else-if="selectedEvent" />
      <v-card-actions
        style="background-color:white;position: fixed;bottom: 0;width: 100%;border-top: 2px solid lightgray">
        <v-btn color="error" text @click="closeDialog" :loading="loading">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer />
        <v-btn color="warning" :disabled="!selectedEvent" @click="back"
          :loading="loading"><v-icon>mdi-chevron-left</v-icon> {{ $t('generic.lang_back') }}
        </v-btn>
        <v-btn color="primary" :disabled="!canBook" @click="book" :loading="loading">{{ $t('accounting.lang_book') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
body{
  overflow-y: auto !important;
  overflow-x: hidden;
}
</style>

<script>
import {mapState} from "vuex";
import validation from "../../../mixins/validation/validation";
import EventsComponent from "./eventbee/EventsComponent";
import EventDetailsComponent from "./eventbee/EventDetailsComponent";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";

export default {
  name: "ButtonsEventbeeDialog",
  components: {EventDetailsComponent, EventsComponent},
  props: {
    showDialog: Boolean,
    posType: String,
  },
  mixins: [validation],
  data() {
    return {
      loading: false,
      selectedEvent:null,
      activePicker:null,
      date:this.$moment(this.getParsedToday).format('YYYY-MM-DD'),
      eventOrder:null,
      menu:false,
    }
  },
  destroyed() {
    this.$root.$off('bookTicket')
  },
  mounted() {
    this.$root.$on('bookTicket',(payload)=>{
      this.prepareEventOrder(payload);
    })
  },
  watch: {
    showDialog(val) {
      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause');
      } else {
        this.selectedEvent = null;
        this.eventOrder = null;
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    date(v){
      this.$refs.events.getEvents();
    },
  },
  computed: {
    ...mapState([
      'pos'
    ]),
    canBook(){
      return this.eventOrder?.canBook && this.isValideTicketNames;
    },
    getParsedToday(){
      return this.$moment.utc().format('YYYY-MM-DD');
    },
    isValideTicketNames(){
      if(!this.selectedEvent?.personalizeTicket)
        return true;

      if(this.eventOrder.selected_seats && this.eventOrder.selected_seats?.length>0){
        return !this.eventOrder.selected_seats.some(el=>  el.ticketNames.length > 0 &&  el.ticketNames.some(name=> !name.salutation || !name.firstName || !name.lastName))
      }
      if(this.eventOrder.selected_categories && this.eventOrder.selected_categories?.length>0){
        return !this.eventOrder.selected_categories.some(el=>  el.ticketNames.length > 0 &&  el.ticketNames.some(name=> !name.salutation || !name.firstName || !name.lastName))
      }
      if(this.eventOrder.selected_prices){
        return !this.eventOrder.selected_prices.some(el=>  el.ticketNames.length > 0 &&  el.ticketNames.some(name=> !name.salutation || !name.firstName || !name.lastName))
      }

      return true;
    }
  },
  provide(){
    return{
      date: () => this.date,
      event: () => this.selectedEvent,
    }
  },
  methods: {
    back() {
      this.selectedEvent = null;
      this.eventOrder = null;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    nextDay() {
      this.date = this.$moment(this.date).add(1, 'days').format('YYYY-MM-DD');
    },
    prevDay() {
      this.date = this.$moment(this.date).subtract(1, 'days').format('YYYY-MM-DD');
    },
    setToday(){
      this.date = this.$moment(this.getParsedToday).format('YYYY-MM-DD');
    },
    prepareEventOrder(order){
      let slotTime = order.selectedTimeSlot?order.selectedTimeSlot:order.selectedArrivalTime;
      this.eventOrder={
        event_uuid:this.selectedEvent.id,
        ticket_uuid:order.ticket_uuid,
        ticket_date:this.$moment(this.date).format('DD.MM.YYYY'),
        slot_start:slotTime?slotTime.from:null,
        slot_end:slotTime?slotTime.to:null,
        selected_prices:order.selectedPrices,
        type:this.selectedEvent.type,
        attribute:order.attribute,
        canBook: order.canBook,
        validFrom: order.validFrom,
        askValidFrom: order.askValidFrom,
        validTime: order.validTime,
        start_time: order.start_time,
        selected_seats: order.hasOwnProperty('selected_seats') ? order.selected_seats : undefined,
        selected_categories: order.hasOwnProperty('selected_categories') && order.selected_categories? order.selected_categories.map(seatCategory => {
          return seatCategory.selectedPriceList.map(price=>({
            categoryId: seatCategory.categoryId,
            categoryName: seatCategory.categoryName,
            ...price
          }))
        }).flat() : undefined,
        end_time: order.end_time
      };
    },
    book(){
        this.loading = true;
        this.axios.post(ENDPOINTS.POS.EVENTBEE.ORDER.CREATE,{
          eventOrder: this.eventOrder,
        }).then( res => {
          if(res.data.status){
            if(this.posType === 'gastro'){
              this.$store.dispatch('pos/gastro/appendOpenItems',{
                party: this.pos.gastro.party,
                orders: res.data.data
              });
            }else if(this.posType === 'retail'){
              this.$store.dispatch('pos/retail/appendItems', {orders:res.data.data});
            }else{
              this.$store.dispatch('pos/wholesaleRetail/appendItems', {orders:res.data.data});
            }
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_actionSuccessful'),
              color: "success"
            });
            this.closeDialog();
          }else{
            Events.$emit("showSnackbar", {
              color: "error",
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
            });
          }
        }).catch( err => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }).finally(()=>{
          this.loading = false;
        })
    }
  },
}
</script>
