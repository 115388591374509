import { Shape } from "konva";
import { COLORS, SEAT_RADIUS } from "./constants";

export class SeatPreview extends Shape {
    constructor(config) {
        super({
            ...config,
            radius: (config.reserved || !config.category) ? 5 : (config.radius || SEAT_RADIUS),
            width: (config.radius || SEAT_RADIUS) * 2,
            height: (config.radius || SEAT_RADIUS) * 2,
            category: config.category || '',
            categoryColor: config.categoryColor || '',
            fontSize: config.fontSize || 10,
            fontColor: "black",
            fontFamily: config.fontFamily || 'Calibri',
            reserved: config.reserved || false,
            booked: config.booked || false,
            fill: config.reserved ? "#808080" : config.fill || "#ffffff",
            stroke: config.stroke || '',
            strokeWidth: 0
        });
        this._centroid = true;
        this.radius = (config.reserved || !config.category) ? 5 : (config.radius || SEAT_RADIUS);
    }

    _sceneFunc(context, shape) {
        const attrs = shape.attrs;

        // Draw the circle
        context.beginPath();
        context.arc(0, 0, attrs.radius, 0, Math.PI * 2, false);
        context.closePath();
        context.fillStrokeShape(shape);
        
        // Draw the seat number only if booked
        if (shape.attrs.booked) {
            context.font = `${attrs.fontSize}px ${attrs.fontFamily}`;
            context.fillStyle = attrs.fontColor;
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(attrs.seat_number, 0, 0);
        }
    }

    _getContrastColor(hexColor) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128 ? 'black' : 'white';
    }

    getWidth() {
        return this.attrs.radius * 2;
    }

    getHeight() {
        return this.attrs.radius * 2;
    }
}

SeatPreview.prototype.className = "SeatPreview";